import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/general/admin";
};


export const loadStateDistrictList = (state, district, data) => {
  const url =
    state === undefined && district === undefined
      ? `${baseURL()}/state`
      : state !== undefined && district === undefined
      ? `${baseURL()}/state/${state}`
      : state !== undefined && district !== undefined
      ? `${baseURL()}/state/${state}/${district}`
      : null;

  return axios.post(url, data);
};


export const loadLanguages = data => {
  const url = `${baseURL()}/languages/list`;
  return axios.post(url, data);
};

export const loadVerificationCode = data => {
  const url = `${baseURL()}/vericode/load`;
  return axios.post(url, data);
};

export const loadDistanceList = data => {
  const url = `${baseURL()}/distance/load`;
  return axios.post(url, data);
};

export const loadTimeSlot = data => {
  const url = `${baseURL()}/timeslot`;
  return axios.post(url, data);
};

export const loadOrderType = (data) => {
  const url = `${baseURL()}/orderType`;
  return axios.post(url, data);
};

export const setOrderType = (id, data) => {
  let url = `${baseURL()}/orderType/set`;

  if(id) url += `/${id}`
  return axios.post(url, data);
};


export const removeOrderType = (id, data) => {
  const url = `${baseURL()}/orderType/remove/${id}`;
  return axios.post(url, data);
};


export const setDistance = (id, data) => {
  let url = `${baseURL()}/distance/set`;

  if(id) url += `/${id}`
  return axios.post(url, data);
};


export const removeDistance = (id, data) => {
  const url = `${baseURL()}/distance/remove/${id}`;
  return axios.post(url, data);
};


export const loadLandfillCostType = (data) => {
  const url = `${baseURL()}/landfillCostType`;
  return axios.post(url, data);
};



export const loadLandfillPoint = (data) => {
  const url = `${baseURL()}/point/landfill/load`;
  return axios.post(url, data);
};

export const loadLandfillPointDetail = (id,data) => {
  const url = `${baseURL()}/point/landfill/load/${id}`;
  return axios.post(url, data);
};

export const addLandfillPoint = (data) => {
  const url = `${baseURL()}/point/landfill/add`;
  return axios.post(url, data);
};

export const editLandfillPoint = (id, data) => {
  const url = `${baseURL()}/point/landfill/update/${id}`;
  return axios.post(url, data);
};

export const removeLandfillPoint = (id, data) => {
  const url = `${baseURL()}/point/landfill/remove/${id}`;
  return axios.post(url,data);
};

export const editRecyclingSalesPrice = (id, data) => {
  const url = `${baseURL()}/point/landfill/salesPrice/${id}`;
  return axios.post(url, data);
};



export const setLandfillAdjustment = (id, data) => {
  const url = `${baseURL()}/point/landfill/adjustment/set/${id}`;
  return axios.post(url, data);
};

export const removeLandfillAdjustment = (id, data) => {
  const url = `${baseURL()}/point/landfill/adjustment/remove/${id}`;
  return axios.post(url,data);
};

export const loadLandfillAdjustment = (data) => {
  const url = `${baseURL()}/point/landfill/adjustment/load`;
  return axios.post(url, data);
};

export const loadLandfillAdjustmentDetail = (id, data) => {
  const url = `${baseURL()}/point/landfill/adjustment/load/${id}`;
  return axios.post(url,data);
};




export const loadWeighingPoint = (data) => {
  const url = `${baseURL()}/point/weighing/load`;
  return axios.post(url, data);
};

export const addWeighingPoint = (data) => {
  const url = `${baseURL()}/point/weighing/add`;
  return axios.post(url, data);
};

export const editWeighingPoint = (id, data) => {
  const url = `${baseURL()}/point/weighing/update/${id}`;
  return axios.post(url, data);
};

export const removeWeighingPoint = (id, data) => {
  const url = `${baseURL()}/point/weighing/remove/${id}`;
  return axios.post(url,data);
};



export const loadSettings = (data) => {
  const url = `${baseURL()}/settings/load`;
  return axios.post(url,data);
};

export const getSettingValue = (data) => {
  const url = `${baseURL()}/settings/value`;
  return axios.post(url,data);
};

export const settingUpdate = (data) => {
  const url = `${baseURL()}/settings/update`;
  return axios.post(url,data);
};



/** Feedback **/
export const loadFeedbackSubjectList = (data) => {
  const url = `${baseURL()}/feedback/subject/load`;
  return axios.post(url, data);
};

export const loadFeedbackList = data => {
  const url = `${baseURL()}/feedback/load`;
  return axios.post(url, data);
};

export const loadFeedbackDetail = (id, data) => {
  const url = `${baseURL()}/feedback/load/${id}`;
  return axios.post(url, data);
};

export const getFeedbackCount = data => {
  const url = `${baseURL()}/feedback/count`;
  return axios.post(url, data);
};

export const resolveFeedback = (id, data) => {
  const url = `${baseURL()}/feedback/resolve/${id}`;
  return axios.post(url, data);
};
/** END Feedback **/


/** Merchant Registration **/
export const loadMerchantRegistrationList = data => {
  const url = `${baseURL()}/feedback/registration/load`;
  return axios.post(url, data);
};

export const loadMerchantRegistrationDetail = (id, data) => {
  const url = `${baseURL()}/feedback/registration/load/${id}`;
  return axios.post(url, data);
};

export const getMerchantRegistrationCount = data => {
  const url = `${baseURL()}/feedback/registration/count`;
  return axios.post(url, data);
};

export const verifyMerchantRegistration = (id, data) => {
  const url = `${baseURL()}/feedback/registration/verify/${id}`;
  return axios.post(url, data);
};

export const rejectMerchantRegistration = (id, data) => {
  const url = `${baseURL()}/feedback/registration/reject/${id}`;
  return axios.post(url, data);
};
/** END Merchant Registration **/


/** Promotion **/
export const loadPromotionList = data => {
  const url = `${baseURL()}/promotionDiscount/load`;
  return axios.post(url, data);
};

export const addPromotion = data => {
  const url = `${baseURL()}/promotionDiscount/create`;
  
  return axios.post(url, data);
};

export const editPromotion = (id, data) => {
  const url = `${baseURL()}/promotionDiscount/update/${id}`;
  return axios.post(url, data);
};

export const deletePromotion = (id, data) => {
  const url = `${baseURL()}/promotionDiscount/remove/${id}`;
  return axios.post(url, data);
};
/** END Promotion **/


export const loadCarbonFootprint = data => {
  const url = `${baseURL()}/carbon/load`;
  return axios.post(url, data);
};

export const setCarbonFootprint = (key, data) => {
  const url = `${baseURL()}/carbon/set/${key}`;  
  return axios.post(url, data);
};

export const overallImpactLoad = (data) => {
  const url = `${baseURL()}/impact/load`;  
  return axios.post(url, data);
};


export const loadReferralCommission = data => {
  const url = `${baseURL()}/referralCommission/load`;
  return axios.post(url, data);
};

export const setReferralCommission = (data) => {
  const url = `${baseURL()}/referralCommission/set`;  
  return axios.post(url, data);
};

export const removeReferralLevel = (id, data) => {
  const url = `${baseURL()}/referralCommission/remove/${id}`;  
  return axios.post(url, data);
};


export const loadInviteFriendSettings = data => {
  const url = `${baseURL()}/inviteFriend/load`;
  return axios.post(url, data);
};

export const setInviteFriendSettings = (data) => {
  const url = `${baseURL()}/inviteFriend/update`;  
  return axios.post(url, data);
};


export const loadDriverSubscriptionSettings = data => {
  const url = `${baseURL()}/driverSubscription/load`;
  return axios.post(url, data);
};

export const setDriverSubscriptionSettings = (data) => {
  const url = `${baseURL()}/driverSubscription/update`;  
  return axios.post(url, data);
};