import * as actionTypes from "./actionTypes";


export const loadStateDistrictList = (data) => {
  return {
    type: actionTypes.LOAD_STATE_DISTRICT_LIST
  };
};

export const loadStateDistrictListSuccess = data => {
  return {
    type: actionTypes.LOAD_STATE_DISTRICT_LIST_SUCCESS,

    data
  };
};


export const loadLanguages = (data) => {
  return {
    type: actionTypes.LOAD_LANGUAGUES,
    data
  };
};

export const loadLanguagesSuccess = data => {

  return {
    type: actionTypes.LOAD_LANGUAGUES_SUCCESS,
    data
  };
};


export const loadLanguagesFail = () => {
  return {
    type: actionTypes.LOAD_LANGUAGUES_FAIL
  };
};



export const loadVerificationCode = data => {
  return {
    type: actionTypes.LOAD_VERIFICATION_CODE,
    data
  };
};

export const loadVerificationCodeSuccess = data => {

  return {
    type: actionTypes.LOAD_VERIFICATION_CODE_SUCCESS,
    data
  };
};


export const loadVerificationCodeFail = () => {
  return {
    type: actionTypes.LOAD_VERIFICATION_CODE_FAIL
  };
};




export const loadOrderType = (data) => {
  return {
    type: actionTypes.LOAD_ORDER_TYPE,
    data
  };
};

export const loadOrderTypeSuccess = data => {

  return {
    type: actionTypes.LOAD_ORDER_TYPE_SUCCESS,
    data
  };
};


export const loadOrderTypeFail = () => {
  return {
    type: actionTypes.LOAD_ORDER_TYPE_FAIL
  };
};



export const setOrderType = (id, data) => {
  return {
    type: actionTypes.SET_ORDER_TYPE,
    data,
    id
  };
};

export const setOrderTypeSuccess = () => {

  return {
    type: actionTypes.SET_ORDER_TYPE_SUCCESS,
  };
};


export const setOrderTypeFail = () => {
  return {
    type: actionTypes.SET_ORDER_TYPE_FAIL
  };
};


export const removeOrderType = (id, data) => {
  return {
    type: actionTypes.REMOVE_ORDER_TYPE,
    id,
    data
  };
};

export const removeOrderTypeSuccess = () => {

  return {
    type: actionTypes.REMOVE_ORDER_TYPE_SUCCESS,
  };
};


export const removeOrderTypeFail = () => {
  return {
    type: actionTypes.REMOVE_ORDER_TYPE_FAIL
  };
};


export const setDistance = (id, data) => {
  return {
    type: actionTypes.SET_DISTANCE,
    data,
    id
  };
};

export const setDistanceSuccess = () => {

  return {
    type: actionTypes.SET_DISTANCE_SUCCESS,
  };
};


export const setDistanceFail = () => {
  return {
    type: actionTypes.SET_DISTANCE_FAIL
  };
};


export const removeDistance = (id, data) => {
  return {
    type: actionTypes.REMOVE_DISTANCE,
    id,
    data
  };
};

export const removeDistanceSuccess = () => {

  return {
    type: actionTypes.REMOVE_DISTANCE_SUCCESS,
  };
};


export const removeDistanceFail = () => {
  return {
    type: actionTypes.REMOVE_DISTANCE_FAIL
  };
};


export const loadLandfillCostType = (data) => {
  return {
    type: actionTypes.LOAD_LANDFILL_COST_TYPE,
    data
  };
};

export const loadLandfillCostTypeSuccess = data => {

  return {
    type: actionTypes.LOAD_LANDFILL_COST_TYPE_SUCCESS,
    data
  };
};


export const loadLandfillCostTypeFail = () => {
  return {
    type: actionTypes.LOAD_LANDFILL_COST_TYPE_FAIL
  };
};


export const loadTimeSlot = (data) => {
  return {
    type: actionTypes.LOAD_TIME_SLOT,
    data
  };
};

export const loadTimeSlotSuccess = data => {

  return {
    type: actionTypes.LOAD_TIME_SLOT_SUCCESS,
    data
  };
};


export const loadTimeSlotFail = () => {
  return {
    type: actionTypes.LOAD_TIME_SLOT_FAIL
  };
};


export const loadDistanceList = (data) => {
  return {
    type: actionTypes.LOAD_DISTANCE_LIST,
    data
  };
};

export const loadDistanceListSuccess = data => {

  return {
    type: actionTypes.LOAD_DISTANCE_LIST_SUCCESS,
    data
  };
};


export const loadDistanceListFail = () => {
  return {
    type: actionTypes.LOAD_DISTANCE_LIST_FAIL
  };
};


/**Landfill Point ***/
export const loadLandfillPoint= data => {
  return {
    type: actionTypes.LANDFILL_POINT_LOAD,
    data
  };
};

export const loadLandfillPointSuccess = data => {

  return {
    type: actionTypes.LANDFILL_POINT_LOAD_SUCCESS,
    data
  };
};


export const loadLandfillPointFail = () => {
  return {
    type: actionTypes.LANDFILL_POINT_LOAD_FAIL
  };
};


export const loadLandfillPointDetail = (id, data) => {
  return {
    type: actionTypes.LANDFILL_POINT_DETAIL,
    id,
    data
  };
};

export const loadLandfillPointDetailSuccess = data => {

  return {
    type: actionTypes.LANDFILL_POINT_DETAIL_SUCCESS,
    data
  };
};


export const loadLandfillPointDetailFail = () => {
  return {
    type: actionTypes.LANDFILL_POINT_DETAIL_FAIL
  };
};

export const addLandfillPoint = data => {
  return {
    type: actionTypes.LANDFILL_POINT_ADD,
    data
  };
};

export const addLandfillPointSuccess = () => {

  return {
    type: actionTypes.LANDFILL_POINT_ADD_SUCCESS
  };
};


export const addLandfillPointFail = () => {
  return {
    type: actionTypes.LANDFILL_POINT_ADD_FAIL
  };
};


export const editLandfillPoint = (id, data) => {
  return {
    type: actionTypes.LANDFILL_POINT_EDIT,
    id,
    data
  };
};

export const editLandfillPointSuccess = () => {

  return {
    type: actionTypes.LANDFILL_POINT_EDIT_SUCCESS
  };
};


export const editLandfillPointFail = () => {
  return {
    type: actionTypes.LANDFILL_POINT_EDIT_FAIL
  };
};

export const removeLandfillPoint = (id, data) => {
  return {
    type: actionTypes.LANDFILL_POINT_REMOVE,
    id,
    data
  };
};

export const removeLandfillPointSuccess = () => {

  return {
    type: actionTypes.LANDFILL_POINT_REMOVE_SUCCESS
  };
};


export const removeLandfillPointFail = () => {
  return {
    type: actionTypes.LANDFILL_POINT_REMOVE_FAIL
  };
};


export const editRecyclingSalesPrice = (id, data) => {
  return {
    type: actionTypes.EDIT_RECYCLING_SALES_PRICE,
    id,
    data
  };
};

export const editRecyclingSalesPriceSuccess = () => {

  return {
    type: actionTypes.EDIT_RECYCLING_SALES_PRICE_SUCCESS
  };
};


export const editRecyclingSalesPriceFail = () => {
  return {
    type: actionTypes.EDIT_RECYCLING_SALES_PRICE_FAIL
  };
};





export const setLandfillAdjustment = (id,data) => {
  return {
    type: actionTypes.LANDFILL_ADJUSTMENT_SET,
    data,
    id
  };
};

export const setLandfillAdjustmentSuccess = () => {

  return {
    type: actionTypes.LANDFILL_ADJUSTMENT_SET_SUCCESS
  };
};


export const setLandfillAdjustmentFail = () => {
  return {
    type: actionTypes.LANDFILL_ADJUSTMENT_SET_FAIL
  };
};


export const removeLandfillAdjustment = (id, data) => {
  return {
    type: actionTypes.LANDFILL_ADJUSTMENT_REMOVE,
    id,
    data
  };
};

export const removeLandfillAdjustmentSuccess = () => {

  return {
    type: actionTypes.LANDFILL_ADJUSTMENT_REMOVE_SUCCESS
  };
};


export const removeLandfillAdjustmentFail = () => {
  return {
    type: actionTypes.LANDFILL_ADJUSTMENT_REMOVE_FAIL
  };
};

export const loadLandfillAdjustment= data => {
  return {
    type: actionTypes.LANDFILL_ADJUSTMENT_LOAD,
    data
  };
};

export const loadLandfillAdjustmentSuccess = data => {

  return {
    type: actionTypes.LANDFILL_ADJUSTMENT_LOAD_SUCCESS,
    data
  };
};


export const loadLandfillAdjustmentFail = () => {
  return {
    type: actionTypes.LANDFILL_ADJUSTMENT_LOAD_FAIL
  };
};


export const loadLandfillAdjustmentDetail = (id, data) => {
  return {
    type: actionTypes.LANDFILL_ADJUSTMENT_DETAIL,
    id,
    data
  };
};

export const loadLandfillAdjustmentDetailSuccess = data => {

  return {
    type: actionTypes.LANDFILL_ADJUSTMENT_DETAIL_SUCCESS,
    data
  };
};


export const loadLandfillAdjustmentDetailFail = () => {
  return {
    type: actionTypes.LANDFILL_ADJUSTMENT_DETAIL_FAIL
  };
};
/** Landfill Point END */


/**Weighing Point ***/
export const loadWeighingPoint= data => {
  return {
    type: actionTypes.WEIGHING_POINT_LOAD,
    data
  };
};

export const loadWeighingPointSuccess = data => {

  return {
    type: actionTypes.WEIGHING_POINT_LOAD_SUCCESS,
    data
  };
};


export const loadWeighingPointFail = () => {
  return {
    type: actionTypes.WEIGHING_POINT_LOAD_FAIL
  };
};



export const addWeighingPoint = data => {
  return {
    type: actionTypes.WEIGHING_POINT_ADD,
    data
  };
};

export const addWeighingPointSuccess = () => {

  return {
    type: actionTypes.WEIGHING_POINT_ADD_SUCCESS
  };
};


export const addWeighingPointFail = () => {
  return {
    type: actionTypes.WEIGHING_POINT_ADD_FAIL
  };
};


export const editWeighingPoint = (id, data) => {
  return {
    type: actionTypes.WEIGHING_POINT_EDIT,
    id,
    data
  };
};

export const editWeighingPointSuccess = () => {

  return {
    type: actionTypes.WEIGHING_POINT_EDIT_SUCCESS
  };
};


export const editWeighingPointFail = () => {
  return {
    type: actionTypes.WEIGHING_POINT_EDIT_FAIL
  };
};

export const removeWeighingPoint = (id, data) => {
  return {
    type: actionTypes.WEIGHING_POINT_REMOVE,
    id,
    data
  };
};

export const removeWeighingPointSuccess = () => {

  return {
    type: actionTypes.WEIGHING_POINT_REMOVE_SUCCESS
  };
};


export const removeWeighingPointFail = () => {
  return {
    type: actionTypes.WEIGHING_POINT_REMOVE_FAIL
  };
};
/** Weighing Point END **/



/** FEEDBACK **/
export const loadFeedbackSubjectList = (data) => {
  
  return {
    type: actionTypes.LOAD_FEEDBACK_SUBJECT_LIST,
    data
  };
};

export const loadFeedbackSubjectListSuccess = data => {
  return {
    type: actionTypes.LOAD_FEEDBACK_SUBJECT_LIST_SUCCESS,
    data
  };
};

export const loadFeedbackSubjectListFail = () => {
  return {
    type: actionTypes.LOAD_FEEDBACK_SUBJECT_LIST_FAIL
  };
};

export const loadFeedbackList = data => {
  
  return {
    type: actionTypes.LOAD_FEEDBACK_LIST,
    data
  };
};

export const loadFeedbackListSuccess = data => {
  return {
    type: actionTypes.LOAD_FEEDBACK_LIST_SUCCESS,
    data
  };
};

export const loadFeedbackListFail = () => {
  return {
    type: actionTypes.LOAD_FEEDBACK_LIST_FAIL
  };
};


export const loadFeedbackDetail = (id, data) => {
  
  return {
    type: actionTypes.LOAD_FEEDBACK_DETAIL,
    id,
    data
  };
};

export const loadFeedbackDetailSuccess = data => {
  return {
    type: actionTypes.LOAD_FEEDBACK_DETAIL_SUCCESS,
    data
  };
};

export const loadFeedbackDetailFail = () => {
  return {
    type: actionTypes.LOAD_FEEDBACK_DETAIL_FAIL
  };
};

export const getFeedbackCount = (data) => {
  
  return {
    type: actionTypes.GET_FEEDBACK_COUNT,
    data
  };
};

export const getFeedbackCountSuccess = data => {
  return {
    type: actionTypes.GET_FEEDBACK_COUNT_SUCCESS,
    data
  };
};

export const getFeedbackCountFail = () => {
  return {
    type: actionTypes.GET_FEEDBACK_COUNT_FAIL
  };
};

export const resolveFeedback = (id,data) => {
  return {
    type: actionTypes.RESOLVE_FEEDBACK,
    id,
    data
  };
};

export const resolveFeedbackSuccess = () => {
  return {
    type: actionTypes.RESOLVE_FEEDBACK_SUCCESS
  };
};

export const resolveFeedbackFail = () => {
  return {
    type: actionTypes.RESOLVE_FEEDBACK_FAIL
  };
};
/** END FEEDBACK **/

/** MERCHANT REGISTRATION **/
export const loadMerchantRegistrationList = data => {
  
  return {
    type: actionTypes.LOAD_MERCHANT_REGISTRATION_LIST,
    data
  };
};

export const loadMerchantRegistrationListSuccess = data => {
  return {
    type: actionTypes.LOAD_MERCHANT_REGISTRATION_LIST_SUCCESS,
    data
  };
};

export const loadMerchantRegistrationListFail = () => {
  return {
    type: actionTypes.LOAD_MERCHANT_REGISTRATION_LIST_FAIL
  };
};

export const loadMerchantRegistrationDetail = (id, data) => {
  
  return {
    type: actionTypes.LOAD_MERCHANT_REGISTRATION_DETAIL,
    id,
    data
  };
};

export const loadMerchantRegistrationDetailSuccess = data => {
  return {
    type: actionTypes.LOAD_MERCHANT_REGISTRATION_DETAIL_SUCCESS,
    data
  };
};

export const loadMerchantRegistrationDetailFail = () => {
  return {
    type: actionTypes.LOAD_MERCHANT_REGISTRATION_DETAIL_FAIL
  };
};



export const getMerchantRegistrationCount = (data) => {
  
  return {
    type: actionTypes.GET_MERCHANT_REGISTRATION_COUNT,
    data
  };
};

export const getMerchantRegistrationCountSuccess = data => {
  return {
    type: actionTypes.GET_MERCHANT_REGISTRATION_COUNT_SUCCESS,
    data
  };
};

export const getMerchantRegistrationCountFail = () => {
  return {
    type: actionTypes.GET_MERCHANT_REGISTRATION_COUNT_FAIL
  };
};


export const verifyMerchantRegistration = (id, data) => {
  return {
    type: actionTypes.VERIFY_MERCHANT_REGISTRATION,
    id,
    data
  };
};

export const verifyMerchantRegistrationSuccess = () => {
  return {
    type: actionTypes.VERIFY_MERCHANT_REGISTRATION_SUCCESS
  };
};

export const verifyMerchantRegistrationFail = () => {
  return {
    type: actionTypes.VERIFY_MERCHANT_REGISTRATION_FAIL
  };
};

export const rejectMerchantRegistration = (id, data) => {
  return {
    type: actionTypes.REJECT_MERCHANT_REGISTRATION,
    id,
    data
  };
};

export const rejectMerchantRegistrationSuccess = () => {
  return {
    type: actionTypes.REJECT_MERCHANT_REGISTRATION_SUCCESS
  };
};

export const rejectMerchantRegistrationFail = () => {
  return {
    type: actionTypes.REJECT_MERCHANT_REGISTRATION_FAIL
  };
};
/** END MERCHANT REGISTRATION **/

/** Promotion **/
export const loadPromotionList = data => {
  
  return {
    type: actionTypes.LOAD_PROMOTION_LIST,
    data
  };
};

export const loadPromotionListSuccess = data => {
  return {
    type: actionTypes.LOAD_PROMOTION_LIST_SUCCESS,
    data
  };
};

export const loadPromotionListFail = () => {
  return {
    type: actionTypes.LOAD_PROMOTION_LIST_FAIL
  };
};

export const addPromotion = data => {
  return {
    type: actionTypes.ADD_PROMOTION,
    data
  };
};

export const addPromotionSuccess = () => {
  return {
    type: actionTypes.ADD_PROMOTION_SUCCESS
  };
};

export const addPromotionFail = () => {
  return {
    type: actionTypes.ADD_PROMOTION_FAIL
  };
};

export const editPromotion = (id,data) => {
  return {
    type: actionTypes.EDIT_PROMOTION,
    id,
    data
  };
};

export const editPromotionSuccess = () => {
  return {
    type: actionTypes.EDIT_PROMOTION_SUCCESS
  };
};

export const editPromotionFail = () => {
  return {
    type: actionTypes.EDIT_PROMOTION_FAIL
  };
};

export const deletePromotion = (id, data) => {
  return {
    type: actionTypes.DELETE_PROMOTION,
    id,
    data
  };
};

export const deletePromotionSuccess = () => {
  return {
    type: actionTypes.DELETE_PROMOTION_SUCCESS
  };
};

export const deletePromotionFail = () => {
  return {
    type: actionTypes.DELETE_PROMOTION_FAIL
  };
};
/** END Promotion **/

/**Setings ***/
export const loadSettings = (data) => {
  return {
    type: actionTypes.SETTING_LOAD,
    data
  };
};

export const loadSettingsSuccess = data => {

  return {
    type: actionTypes.SETTING_LOAD_SUCCESS,
    data
  };
};


export const loadSettingsFail = () => {
  return {
    type: actionTypes.SETTING_LOAD_FAIL
  };
};


export const getSettingValue = (data) => {
  return {
    type: actionTypes.GET_SETTING_VALUE,
    data
  };
};

export const getSettingValueSuccess = data => {

  return {
    type: actionTypes.GET_SETTING_VALUE_SUCCESS,
    data
  };
};


export const getSettingValueFail = () => {
  return {
    type: actionTypes.GET_SETTING_VALUE_FAIL
  };
};


export const settingUpdate = data => {
  return {
    type: actionTypes.SETTING_UPDATE,
    data
  };
};

export const settingUpdateSuccess = () => {

  return {
    type: actionTypes.SETTING_UPDATE_SUCCESS
  };
};


export const settingUpdateFail = () => {
  return {
    type: actionTypes.SETTING_UPDATE_FAIL
  };
};




export const loadCarbonFootprint = (data) => {
  return {
    type: actionTypes.LOAD_CARBON_FOOTPRINT,
    data
  };
};

export const loadCarbonFootprintSuccess = data => {

  return {
    type: actionTypes.LOAD_CARBON_FOOTPRINT_SUCCESS,
    data
  };
};


export const loadCarbonFootprintFail = () => {
  return {
    type: actionTypes.LOAD_CARBON_FOOTPRINT_FAIL
  };
};



export const setCarbonFootprint = (key, data) => {
  return {
    type: actionTypes.SET_CARBON_FOOTPRINT,
    data,
    key
  };
};

export const setCarbonFootprintSuccess = () => {

  return {
    type: actionTypes.SET_CARBON_FOOTPRINT_SUCCESS,
  };
};


export const setCarbonFootprintFail = () => {
  return {
    type: actionTypes.SET_CARBON_FOOTPRINT_FAIL
  };
};


export const overallImpactLoad = (data) => {
  return {
    type: actionTypes.LOAD_OVERALL_IMPACT,
    data
  };
};

export const overallImpactLoadSuccess = data => {

  return {
    type: actionTypes.LOAD_OVERALL_IMPACT_SUCCESS,
    data
  };
};


export const overallImpactLoadFail = () => {
  return {
    type: actionTypes.LOAD_OVERALL_IMPACT_FAIL
  };
};






export const loadReferralCommission = (data) => {
  return {
    type: actionTypes.LOAD_REFERRAL_COMMISSION,
    data
  };
};

export const loadReferralCommissionSuccess = data => {

  return {
    type: actionTypes.LOAD_REFERRAL_COMMISSION_SUCCESS,
    data
  };
};


export const loadReferralCommissionFail = () => {
  return {
    type: actionTypes.LOAD_REFERRAL_COMMISSION_FAIL
  };
};



export const setReferralCommission = (data) => {
  return {
    type: actionTypes.SET_REFERRAL_COMMISSION,
    data
  };
};

export const setReferralCommissionSuccess = () => {

  return {
    type: actionTypes.SET_REFERRAL_COMMISSION_SUCCESS,
  };
};


export const setReferralCommissionFail = () => {
  return {
    type: actionTypes.SET_REFERRAL_COMMISSION_FAIL
  };
};



export const removeReferralLevel = (id, data) => {
  return {
    type: actionTypes.REMOVE_REFERRAL_LEVEL,
    data,
    id
  };
};

export const removeReferralLevelSuccess = () => {

  return {
    type: actionTypes.REMOVE_REFERRAL_LEVEL_SUCCESS,
  };
};


export const removeReferralLevelFail = () => {
  return {
    type: actionTypes.REMOVE_REFERRAL_LEVEL_FAIL
  };
};






export const loadInviteFriendSettings = (data) => {
  return {
    type: actionTypes.LOAD_INVITE_FRIEND_SETTING,
    data
  };
};

export const loadInviteFriendSettingsSuccess = data => {

  return {
    type: actionTypes.LOAD_INVITE_FRIEND_SETTING_SUCCESS,
    data
  };
};


export const loadInviteFriendSettingsFail = () => {
  return {
    type: actionTypes.LOAD_INVITE_FRIEND_SETTING_FAIL
  };
};



export const setInviteFriendSettings = (data) => {
  return {
    type: actionTypes.SET_INVITE_FRIEND_SETTING,
    data
  };
};

export const setInviteFriendSettingsSuccess = () => {

  return {
    type: actionTypes.SET_INVITE_FRIEND_SETTING_SUCCESS,
  };
};


export const setInviteFriendSettingsFail = () => {
  return {
    type: actionTypes.SET_INVITE_FRIEND_SETTING_FAIL
  };
};




export const loadDriverSubscriptionSettings = (data) => {
  return {
    type: actionTypes.LOAD_DRIVER_SUBSCRIPTION_SETTING,
    data
  };
};

export const loadDriverSubscriptionSettingsSuccess = data => {

  return {
    type: actionTypes.LOAD_DRIVER_SUBSCRIPTION_SETTING_SUCCESS,
    data
  };
};


export const loadDriverSubscriptionSettingsFail = () => {
  return {
    type: actionTypes.LOAD_DRIVER_SUBSCRIPTION_SETTING_FAIL
  };
};



export const setDriverSubscriptionSettings = (data) => {
  return {
    type: actionTypes.SET_DRIVER_SUBSCRIPTION_SETTING,
    data
  };
};

export const setDriverSubscriptionSettingsSuccess = () => {

  return {
    type: actionTypes.SET_DRIVER_SUBSCRIPTION_SETTING_SUCCESS,
  };
};


export const setDriverSubscriptionSettingsFail = () => {
  return {
    type: actionTypes.SET_DRIVER_SUBSCRIPTION_SETTING_FAIL
  };
};
/** Setings END */